import React from 'react';
import classes from "../ProductItem.module.scss";

const Row = ({image, text}) => {
    return (
        <div className={classes.ProductItem__item}>
            <img
                src={image}
                loading={"lazy"}
                alt={"description-icon"}
                width={"20"}
                height={"20"}
            />
            <p>{text}</p>
        </div>
    );
};

export default Row;
