import React, {FC} from "react";
import {Outlet} from "react-router-dom";

const ClientLayout = () => {
    return (
        <Outlet />
    )
}

export default ClientLayout
