import {
    CHANGE_DELETE_DIALOG,
    SET_DESCRIPTIONS,
    SET_SIZES,
    SET_CURRENT_USER,
    DELETE_CURRENT_USER
} from "../actions/actionTypes.js";

let user = JSON.parse(localStorage.getItem("user"));

const initialState = {
    user,
    descriptions: {
        en: null,
        ru: null
    },
    sizes: {
        one: null,
        two: null,
        three: null
    },
    dialogs: {
        deleteWarningDialog: false
    }
};

export const adminReducer = (state = initialState, action) => {

    const data = action.payload;

    switch (action.type) {
        case SET_CURRENT_USER:
            return {...state, user: data}
        case DELETE_CURRENT_USER:
            return {...state, user: null}
        case CHANGE_DELETE_DIALOG:
            return {
                ...state,
                dialogs: {
                    ...state,
                    deleteWarningDialog: !state.dialogs.deleteWarningDialog
                }
            }
        case SET_SIZES:
            return {
                ...state,
                sizes: {
                    ...state.sizes,
                    one: data.one,
                    two: data.two,
                    three: data.three
                }
            }
        case SET_DESCRIPTIONS:
            return {
                ...state,
                descriptions: {
                    en: data.en,
                    ru: data.ru
                }
            }
        default:
            return state
    }
}
