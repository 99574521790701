import React from "react";

export const AdminFormValidationHelper = (values, setIsValid) => {
    if (values.type === "") {
        setIsValid(false);
    } else if (values.description === "") {
        setIsValid(false);
    } else if (values.title === "") {
        setIsValid(false);
    } else if (values.shortTitle === "") {
        setIsValid(false);
    } else if (values.sizes.sizeOne === "") {
        setIsValid(false);
    } else if (values.sizes.sizeTwo === "") {
        setIsValid(false);
    } else if (values.sizes.sizeThree === "") {
        setIsValid(false);
    } else if (values.price === "") {
        setIsValid(false);
    } else if (values.unit === "") {
        setIsValid(false);
    } else if (values.available === "") {
        setIsValid(false);
    } else if (values.code === "") {
        setIsValid(false);
    } else if (values.type === "") {
        setIsValid(false);
    } else if (values.amount === "") {
        setIsValid(false);
    } else if (values.deadlineSupplies === "") {
        setIsValid(false);
    } else if (values.en.type === "" || values.en.description === "" || values.en.unit === "" || values.en.title === "" || values.en.shortTitle === "" || values.en.deadlineSupplies === "") {
        setIsValid(false);
    } else {
        setIsValid(true);
    }
}
