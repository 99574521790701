import React, {useEffect, useState} from 'react';
import AppRouter from "./AppRouter";
import {LANGUAGE_CHECKER} from "./variables/variables";
import ExcelJS from "exceljs";
import {useDispatch} from "react-redux";
import {SET_PRODUCTS_LIST} from "./store/actions/actionTypes.js";

function App() {

    const dispatch = useDispatch();

    useEffect(() => {
        if (LANGUAGE_CHECKER) {
            return localStorage.setItem("language", "en");
        }
    }, [])

    const fetchAndProcessFile = async () => {
        try {
            // Step 1: Fetch the XLSX file
            const response = await fetch("/data.xlsx");
            if (!response.ok) {
                throw new Error("Failed to fetch the file.");
            }

            // Step 2: Read the file as an ArrayBuffer
            const arrayBuffer = await response.arrayBuffer();

            // Step 3: Load the workbook
            const workbook = new ExcelJS.Workbook();
            await workbook.xlsx.load(arrayBuffer);

            // Step 4: Get the first worksheet
            const worksheet = workbook.worksheets[0];
            let rows = [];

            // Step 5: Process each row into an object with indexed keys
            worksheet.eachRow((row, rowIndex) => {
                const rowObject = {};
                row.eachCell((cell, colIndex) => {
                    rowObject[colIndex - 1] = cell.value; // 0-based indexing for columns
                });
                rows[rowIndex] = rowObject; // Add to rows array
            });

            rows = rows.filter((row) => {
                return row !== undefined && row["0"] && row["0"] !== "АРТ";
            });


            // // Step 6: Extract images and associate them with rows
            workbook.eachSheet((sheet) => {
                let rowIndex = 0;
                sheet.getImages().forEach((image) => {
                    const imageData = workbook.getImage(image.imageId);
                    const base64 = `data:image/${imageData.extension};base64,${imageData.buffer.toString(
                        "base64"
                    )}`;

                    if (rows[rowIndex]) {
                        rows[rowIndex].image = base64;
                        rowIndex++;
                    }
                });
            });

            dispatch({type: SET_PRODUCTS_LIST, payload: rows});
        } catch (error) {
            console.error("Error processing file:", error);
        }
    };


    useEffect(() => {
        fetchAndProcessFile();
    }, []);

    return (
        <AppRouter/>
    );
}

export default App;
