import React from "react";
import classes from "./Button.module.scss";

const Button = ({type, onClick, label,id}) => {
    return (
        <button
            id={id}
            type={type}
            onClick={onClick}
            className={classes.Button}
        >
            {label}
        </button>
    )
}

export default Button
