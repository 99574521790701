import React, {useEffect} from 'react';
import classes from "./SuccessAlert.module.scss";
import {useClientSelector} from "../../../store/selectors/useClientSelector";
import {useActions} from "../../../hooks/useActions/useActions.js";

export const SuccessAlert = () => {

    const state = useClientSelector();
    const {showSuccessAlertAction} = useActions();

    useEffect(() => {
        if (state.success.show) {
            setTimeout(() => {
                showSuccessAlertAction("");
            }, 5000)
        }
    }, [state.success.show])

    return (
        state.success.show
            ?
            <div className={classes.SuccessAlert}>
                <span>{state.success.message}</span>
            </div>
            :
            null
    );
};
