import React, {FC, useEffect} from 'react';
import AdminControlList from "../../../components/admin/AdminControlList/AdminControlList";
import {useActions} from "../../../hooks/useActions/useActions.js";

const AdminPage = () => {

    const {setDescriptionsAction, setSizesAction, getProductsListAction} = useActions();

    useEffect(() => {
        document.title = "Админ панель";
        setDescriptionsAction();
        getProductsListAction();
        setSizesAction();
    }, [])

    return (
        <>
            <div className="container">
                <AdminControlList/>
            </div>
        </>
    );
};

export default AdminPage;
