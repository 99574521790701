import React, {FC, lazy, Suspense} from "react";
import {Routes, Route} from "react-router-dom";
import ClientLayout from "./hoc/ClientLayout/ClientLayout.jsx";
import EmptyPage from "./pages/client/empty-page/EmptyPage.jsx";
import AdminLayout from "./hoc/AdminLayout/AdminLayout.jsx";
import AdminPage from "./pages/admin/admin-page/AdminPage.jsx";
import ProductsPage from "./pages/admin/products-page/ProductsPage.jsx";
import OptionsPage from "./pages/admin/options-page/OptionsPage.jsx";
import ProductDetail from "./pages/admin/products-page/detail-page/ProductDetail.jsx";
import AuthPage from "./pages/admin/auth-page/AuthPage.jsx";
import SecurityRoute from "./helpers/SecurityRoute/SecurityRoute.jsx";
import {useAdminSelector} from "./store/selectors/useAdminSelector";

const MainPage = lazy(() => import("./pages/client/main-page/MainPage.jsx"));

const AppRouter = () => {

    const state = useAdminSelector();

    return (
        <Routes>
            <Route path={"/"} element={<ClientLayout />}>
                <Route
                    index
                    element={
                        <Suspense fallback={""}>
                            <MainPage />
                        </Suspense>
                    }
                />
                <Route path={"*"} element={<EmptyPage />} />
            </Route>
            { state.user === null ? <Route path={"/auth"} element={<AuthPage />} /> : null }
            <Route path={"/admin/"} element={<AdminLayout />}>
                <Route
                    index
                    element={
                        <SecurityRoute>
                            <AdminPage />
                        </SecurityRoute>
                    }
                />
                <Route
                    path={"products"}
                    element={
                        <SecurityRoute>
                            <ProductsPage />
                        </SecurityRoute>
                    }
                />
                <Route
                    path={"products/:id"}
                    element={
                        <SecurityRoute>
                            <ProductDetail />
                        </SecurityRoute>
                    }
                />
                <Route
                    path={"options"}
                    element={
                        <SecurityRoute>
                            <OptionsPage />
                        </SecurityRoute>
                    }
                />
            </Route>
        </Routes>
    )
}

export default AppRouter
