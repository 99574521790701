import React, {FC} from "react";
import classes from "./Input.module.scss";

const Input = ({type,defaultValue, value, onChange, name, label, placeholder,onKeyPress, onBlur}) => {
    return (
        <>
            { label !== undefined ? <p>{label}</p> : null }
            <input
                min={0}
                defaultValue={defaultValue}
                onKeyPress={onKeyPress}
                onBlur={onBlur}
                type={type}
                value={value}
                className={classes.Input}
                placeholder={placeholder}
                onChange={onChange}
                name={name}
            />
        </>
    )
}

export default Input
