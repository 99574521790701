import React, {FC} from 'react';
import classes from "../AdminControlList.module.scss";
import Input from "../../../../UI/Input/Input";

const ControlListItem = ({defaultValue, type, value, name, label, placeholder, onChange}) => {
    return (
        <div className={classes.AdminControlList__item}>
            <Input
                defaultValue={defaultValue}
                type={type}
                value={value}
                onChange={onChange}
                name={name}
                label={label}
                placeholder={placeholder}
            />
        </div>
    );
};

export default ControlListItem;
