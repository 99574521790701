import React, {FC, useEffect} from 'react';
import classes from "./DetailItem.module.scss";
import DeleteWarningDialog from "./DeleteWarningDialog/DeleteWarningDialog.jsx";
import {useActions} from "../../../../hooks/useActions/useActions.js";
import {useAdminSelector} from "../../../../store/selectors/useAdminSelector";
import UpdateControl from "./UpdateControl/UpdateControl.jsx";

const DetailItem = ({product}) => {

    const {changeDeleteDialogStateAction, setSizesAction, setDescriptionsAction} = useActions();
    const state = useAdminSelector();

    const handleDelete = () => {
        changeDeleteDialogStateAction();
    }

    useEffect(() => {
        setSizesAction();
        setDescriptionsAction();
    }, [])

    return (
        <>
            { state.dialogs.deleteWarningDialog ?  <DeleteWarningDialog /> : null }
            <div className={classes.DetailItem}>
                <div className={classes.DetailItem__top}>
                    <h2>Изменение товара</h2>
                    <div>
                        <button onClick={handleDelete} className={classes.DetailItem__deleteButton}>
                            Удалить товар
                        </button>
                    </div>
                </div>
                <UpdateControl product={product} />
            </div>
        </>
    );
};

export default DetailItem;
