import React, {FC} from 'react';
import OptionsList from "../../../components/admin/OptionsList/OptionsList";

const OptionsPage = () => {
    return (
        <div className={"container"}>
            <OptionsList />
        </div>
    );
};

export default OptionsPage;
