import React from "react";
import classes from "./Select.module.scss";

const Select = ({value, defaultValue, onChange, name, options}) => {
    return (
        <select
            className={classes.Select}
            value={value}
            defaultValue={defaultValue}
            onChange={onChange}
            name={name}
        >
            <option disabled value={""}>Выберите значение..</option>
            {
                options?.length
                    ?
                    options.map((option, index) => {
                        return (
                            <option key={index} value={option}>{option}</option>
                        )
                    })
                    :
                    null
            }
        </select>
    )
}

export default Select
