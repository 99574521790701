import React, {useState} from 'react';
import classes from "./OptionsList.module.scss";
import OptionsTab from "./OptionsTab/OptionsTab.jsx";
import OptionsHeader from "./OptionsHeader/OptionsHeader.jsx";

const OptionsList = () => {

    const [activeTab, setActiveTab] = useState("one");

    return (
        <div className={classes.OptionsList}>
            <OptionsHeader
                setActiveTab={setActiveTab}
            />
            <div className={classes.OptionsList__content}>
                <OptionsTab
                    activeTab={activeTab}
                />
            </div>
        </div>
    );
};

export default OptionsList;
