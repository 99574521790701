import React from 'react';
import classes from "./Header.module.scss";
import {Link, useNavigate} from "react-router-dom";
import {useActions} from "../../../hooks/useActions/useActions.js";
import {useAdminSelector} from "../../../store/selectors/useAdminSelector";

const Header = () => {

    const state = useAdminSelector();
    const {deleteCurrentUserAction} = useActions();
    const navigate = useNavigate();

    const onLogout = () => {
        deleteCurrentUserAction();
        navigate(`/auth`);
    }

    return (
        <div className={classes.Header}>
            <div className={classes.Header__content}>
                <ul className={classes.Header__list}>
                    <li className={classes.Header__listItem}>
                        <Link to={"/admin"}>
                            Главная
                        </Link>
                    </li>
                    <li className={classes.Header__listItem}>
                        <Link to={"/admin/products"}>
                            Товары
                        </Link>
                    </li>
                    <li className={classes.Header__listItem}>
                        <Link to={"/admin/options"}>
                            Опции
                        </Link>
                    </li>
                </ul>
                <ul className={classes.Header__list}>
                    <li className={classes.Header__listItem}>
                        <p className={classes.Header__userEmail}>{state?.user?.email}</p>
                    </li>
                    <li className={classes.Header__listItem}>
                        <button
                            onClick={onLogout}
                            className={classes.Header__logout}
                        >
                            Выход
                        </button>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default Header;
