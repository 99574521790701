import React, {FC, useEffect} from 'react';
import classes from "./EmptyPage.module.scss";
import Button from "../../../UI/Button/Button";
import {useNavigate} from "react-router-dom";

const EmptyPage = () => {

    const navigate = useNavigate();

    useEffect(() => {
        document.title = "Страница не найдена";
    }, [])

    const redirectHandler = () => {
        return navigate("/");
    }

    return (
        <div className={classes.EmptyPage}>
            <h2>Страница не найдена :(</h2>
            <div className={classes.EmptyPage__buttonWrapper}>
                <Button
                    type={"button"}
                    onClick={redirectHandler}
                    label={"На главную страницу"}
                />
            </div>
        </div>
    );
};

export default EmptyPage;
