import React, {FC, useState} from 'react';
import {Formik} from "formik";
import classes from "./AuthControl.module.scss";
import Button from "../../../UI/Button/Button";
import ControlListItem from "../AdminControlList/ControlListItem/ControlListItem";
import {useActions} from "../../../hooks/useActions/useActions.js";
import ErrorAlert from "../../../UI/Alert/ErrorAlert/ErrorAlert";
import {VALIDATION_MESSAGE} from "../../../variables/variables";
import {useNavigate} from "react-router-dom";

const AuthControl = () => {

        const [isValid, setIsValid] = useState(false);
        const {showErrorAlertAction, setCurrentUserAction} = useActions();
        const navigate = useNavigate();

        return (
            <Formik
                validate={values => {
                    if (values.email === "" || values.password === "") {
                        setIsValid(false);
                    } else {
                        setIsValid(true);
                    }
                }}
                initialValues={{
                    email: "",
                    password: ""
                }}
                onSubmit={values => {
                    if (isValid) {

                        let email = values.email;
                        let password = values.password;

                    } else {
                        showErrorAlertAction(VALIDATION_MESSAGE);
                    }
                }}
            >
                {({
                      values,
                      handleChange,
                      handleSubmit
                  }) => (
                    <>
                        <ErrorAlert />
                        <div className={classes.AuthControl}>
                            <div className={classes.AuthControl__content}>
                                <h2 className={classes.AuthControl__title}>Вход в админ панель</h2>
                                <div className={classes.AuthControl__item}>
                                    <ControlListItem
                                        label={"Электронная почта"}
                                        type={"text"}
                                        value={values.email}
                                        onChange={handleChange}
                                        placeholder={"example@mail.com"}
                                        name={"email"}
                                    />
                                </div>
                                <div className={classes.AuthControl__item}>
                                    <ControlListItem
                                        label={"Пароль"}
                                        type={"password"}
                                        value={values.password}
                                        onChange={handleChange}
                                        placeholder={"Значение.."}
                                        name={"password"}
                                    />
                                </div>
                                <Button id={"a"} type={"submit"} onClick={handleSubmit} label={"Вход"}/>
                            </div>
                        </div>
                    </>
                )}
            </Formik>
        );
    }
;

export default AuthControl;
