import React, {FC} from 'react';
import Header from "../../components/admin/Header/Header";
import {Outlet} from "react-router-dom";

const AdminLayout = () => {
    return (
        <>
            <Header />
            <Outlet />
        </>
    );
};

export default AdminLayout;
