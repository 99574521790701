import React, {FC, useState} from 'react';
import classes from "./DescriptionControl.module.scss";
import ControlListItem from "../../../AdminControlList/ControlListItem/ControlListItem";
import Button from "../../../../../UI/Button/Button";
import {useActions} from "../../../../../hooks/useActions/useActions.js";
import {VALIDATION_MESSAGE} from "../../../../../variables/variables";
import ErrorAlert from "../../../../../UI/Alert/ErrorAlert/ErrorAlert";
import {SuccessAlert} from "../../../../../UI/Alert/SuccessAlert/SuccessAlert";
import {useClientSelector} from "../../../../../store/selectors/useClientSelector";
import LanguageControl from "../../../LanguageControl/LanguageControl";

const TypesControl = () => {

    const state = useClientSelector();
    const [language, setLanguage] = useState("ru");
    const [description, setDescription] = useState({
        en: "",
        ru: ""
    });
    const {showErrorAlertAction, showSuccessAlertAction} = useActions();

    const handleChange = (event, language) => {
        switch (language) {
            case "ru":
                setDescription({
                    ...description,
                    ru: event.target.value
                });
                break
            case "en":
                setDescription({
                    ...description,
                    en: event.target.value
                });
        }
    };

    const handleSubmit = () => {
        if (description.ru !== "" && description.en !== "") {

        } else {
            if (!state.error.show) showErrorAlertAction(VALIDATION_MESSAGE);
        }
    }

    return (
        <>
            <ErrorAlert/>
            <SuccessAlert/>
            <div className={classes.DescriptionControl}>
                <div className={classes.TypesControl__content}>
                    <LanguageControl
                        language={language}
                        setLanguage={setLanguage}
                    />
                    <div className={classes.DescriptionControl__item}>
                        {
                            language === "ru"
                                ?
                                <ControlListItem
                                    type={"text"}
                                    value={description.ru}
                                    onChange={(event) => handleChange(event, "ru")}
                                    placeholder={"Значение.."}
                                    label={"Характеристика"}
                                />
                                :
                                <ControlListItem
                                    type={"text"}
                                    value={description.en}
                                    onChange={(event) => handleChange(event, "en")}
                                    placeholder={"Значение.."}
                                    label={"Характеристика"}
                                />
                        }
                    </div>
                    <Button type={"submit"} onClick={handleSubmit} label={"Сохранить"}/>
                </div>
            </div>
        </>
    );
};

export default TypesControl;
