import React, {FC, useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import DetailItem from "../../../../components/admin/ProductsList/DetailItem/DetailItem";

const ProductDetail = () => {

    const {id} = useParams();
    const [productItem, setProductItem] = useState(null);

    return (
        <div className="container">
            {
                productItem
                    ?
                    <DetailItem
                        product={productItem}
                    />
                    :
                    <p>Загрузка..</p>
            }
        </div>
    );
};

export default ProductDetail;
