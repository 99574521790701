import React from 'react';
import classes from "./Dialog.module.scss";

const Dialog = ({children, title, actionName, handleSubmit, closeHandler}) => {
    return (
        <>
            <div className={classes.Dialog__backDrop}> </div>
            <div className={classes.Dialog}>
                <div className={classes.Dialog__content}>
                    <div className={classes.Dialog__header}>
                        <p className={classes.Dialog__title}>{title}</p>
                        <button onClick={closeHandler} className={classes.Dialog__closeButton}>
                            <img src={"/images/close-icon.svg"} width={"30"} height={"30"} alt={"close-button"} />
                        </button>
                    </div>
                    {children}
                    <div className={classes.Dialog__footer}>
                        <button onClick={handleSubmit} className={classes.Dialog__submitButton}>{actionName}</button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Dialog;
