import React, {FC} from 'react';
import classes from "../../../components/admin/AuthControl/AuthControl.module.scss";
import AuthControl from "../../../components/admin/AuthControl/AuthControl";

const AuthPage = () => {
    return (
        <div className={classes.AuthPage}>
            <AuthControl />
        </div>
    );
};

export default AuthPage;
