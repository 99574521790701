import React from 'react';
import classes from "./LanguageControl.module.scss";

const LanguageControl = ({language, setLanguage}) => {

    const handleLanguage = (event) => {
        switch (event.target.lang) {
            case "en":
                setLanguage("en");
                break
            case "ru":
                setLanguage("ru");
        }
    }

    return (
        <div className={classes.LanguageControl}>
            <button className={language === "ru" ? classes.Active : ""} lang={"ru"}
                    onClick={handleLanguage}>Русский
            </button>
            <button className={language === "en" ? classes.Active : ""} lang={"en"}
                    onClick={handleLanguage}>Английский
            </button>
        </div>

    );
};

export default LanguageControl;
