import {
    SET_UPDATE_DATE,
    SHOW_ERROR_ALERT,
    SHOW_SUCCESS_ALERT,
    SET_PRODUCTS_LIST,
    GET_TITLES,
    SET_SEARCH_VALUE,
    SET_SEARCHED_PRODUCTS,
    CHANGE_UNCOVER_STATE,
    SET_LANGUAGE,
    CHANGE_OPTION_DIALOG,
    REMOVE_FILTERS,
    SET_FILTER_BY_TYPE,
    REMOVE_FILTER_BY_TYPE,
    SET_FILTER_BY_DESCRIPTION,
    REMOVE_FILTER_BY_DESCRIPTION,
    SET_FILTER_BY_INDEX,
    REMOVE_FILTER_BY_INDEX,
    SET_FILTER_BY_SIZE_TWO,
    SET_FILTER_BY_SIZE_THREE,
    SET_FILTER_BY_SIZE_ONE,
    SET_FILTER_BY_CODE,
    REMOVE_FILTER_BY_CODE,
    SET_FILTER_BY_NAME,
    REMOVE_FILTER_BY_NAME,
    SET_FILTER_BY_DELIVERY_TIME, REMOVE_FILTER_BY_DELIVERY_TIME, SET_FILTER_BY_COUNT, REMOVE_FILTER_BY_COUNT
} from "../actions/actionTypes.js";

const filteredInitialState = {
    index: false,
    type: false,
    code: false,
    name: false,
    deliveryTime: false,
    description: false,
    sizeTwo: false,
    sizeThree: false,
    sizeOne: false,
    count: false
};

const initialState = {
    lastUpdateDate: null,
    checked: {
        types: null,
        deliveryTimes: null
    },
    countControl: {
        from: "",
        to: ""
    },
    control: {
        from: "",
        to: ""
    },
    products: null,
    titles: null,
    searchValue: "",
    filtered: filteredInitialState,
    uncover: false,
    productsWithoutFilters: null,
    language: localStorage.getItem("language"),
    dialogs: {
        type: false,
        index: false,
        description: false,
        code: false,
        deliveryTime: false,
        name: false,
        count: false
    },
    error: {
        show: false,
        message: ""
    },
    success: {
        show: false,
        message: ""
    }
};

export const rootReducer = (state = initialState, action) => {

    let data = action.payload;

    switch (action.type) {
        case SET_PRODUCTS_LIST:
            return {...state, products: data, productsWithoutFilters: data, filtered: false}
        case GET_TITLES:
            return {...state, titles: data}
        case SET_SEARCH_VALUE:
            return {...state, searchValue: data}
        case SET_SEARCHED_PRODUCTS:
            return {...state, products: data}
        case CHANGE_UNCOVER_STATE:
            return {...state, uncover: !state.uncover}
        case SET_LANGUAGE:
            return {
                ...state,
                language: data
            }
        case CHANGE_OPTION_DIALOG:
            return {
                ...state,
                dialogs: {
                    ...state.dialogs,
                    [data.key]: data.state
                }
            }
        case SET_FILTER_BY_TYPE:
            return {
                ...state,
                products: data.products,
                filtered: {
                    ...state.filtered,
                    type: true
                },
                checked: {
                    ...state.checked,
                    types: data.types
                }
            }
        case REMOVE_FILTER_BY_TYPE:
            return {
                ...state,
                filtered: {
                    ...state.filtered,
                    type: false
                },
                checked: {
                    ...state.checked,
                    types: null
                }
            }
        case SET_FILTER_BY_DELIVERY_TIME:
            return {
                ...state,
                products: data.products,
                filtered: {
                    ...state.filtered,
                    deliveryTime: true
                },
                checked: {
                    ...state.checked,
                    deliveryTimes: data.deliveryTimes
                }
            }
        case REMOVE_FILTER_BY_DELIVERY_TIME:
            return {
                ...state,
                filtered: {
                    ...state.filtered,
                    deliveryTime: false
                },
                checked: {
                    ...state.checked,
                    deliveryTimes: null
                }
            }
        case SET_FILTER_BY_CODE:
            return {
                ...state,
                products: data.products,
                filtered: {
                    ...state.filtered,
                    code: true
                }
            }
        case SET_FILTER_BY_NAME:
            return {
                ...state,
                products: data.products,
                filtered: {
                    ...state.filtered,
                    name: true
                }
            }
        case REMOVE_FILTER_BY_NAME:
            return {
                ...state,
                filtered: {
                    ...state.filtered,
                    name: false
                }
            }
        case REMOVE_FILTER_BY_CODE:
            return {
                ...state,
                filtered: {
                    ...state.filtered,
                    code: false
                }
            }
        case SET_FILTER_BY_DESCRIPTION:
            return {
                ...state,
                products: data.products,
                filtered: {
                    ...state.filtered,
                    description: true
                },
                checked: {
                    ...state.checked,
                    descriptions: data.descriptions
                }
            }
        case REMOVE_FILTER_BY_DESCRIPTION:
            return {
                ...state,
                filtered: {
                    ...state.filtered,
                    description: false
                },
                checked: {
                    ...state.checked,
                    descriptions: null
                }
            }
        case SET_FILTER_BY_INDEX:
            return {
                ...state,
                products: data.products,
                filtered: {
                    ...state.filtered,
                    index: true
                },
                control: {
                    ...state.control,
                    to: data.control.to,
                    from: data.control.from
                }
            }
        case REMOVE_FILTER_BY_INDEX:
            return {
                ...state,
                filtered: {
                    ...state.filtered,
                    index: false
                }
            }
        case SET_FILTER_BY_COUNT:
            return {
                ...state,
                products: data.products,
                filtered: {
                    ...state.filtered,
                    count: true
                },
                countControl: {
                    ...state.countControl,
                    to: data.control.to,
                    from: data.control.from
                }
            }
        case REMOVE_FILTER_BY_COUNT:
            return {
                ...state,
                filtered: {
                    ...state.filtered,
                    count: false
                }
            }
        case SET_FILTER_BY_SIZE_ONE:
            return {
                ...state,
                products: data,
                filtered: {
                    ...state.filtered,
                    sizeOne: !state.filtered.sizeOne
                }
            }
        case SET_FILTER_BY_SIZE_TWO:
            return {
                ...state,
                products: data,
                filtered: {
                    ...state.filtered,
                    sizeTwo: !state.filtered.sizeTwo
                }
            }
        case SET_FILTER_BY_SIZE_THREE:
            return {
                ...state,
                products: data,
                filtered: {
                    ...state.filtered,
                    sizeThree: !state.filtered.sizeThree
                }
            }
        case SHOW_ERROR_ALERT:
            return {
                ...state,
                error: {
                    message: data || state.error.message,
                    show: !state.error.show
                }
            };
        case SHOW_SUCCESS_ALERT:
            return {
                ...state,
                success: {
                    message: data || state.success.message,
                    show: !state.success.show
                }
            };
        case SET_UPDATE_DATE:
            return {
                ...state,
                lastUpdateDate: data
            }
        case REMOVE_FILTERS:
            return {
                ...state,
                products: state.productsWithoutFilters,
                filtered: filteredInitialState
            }
        default:
            return state
    }
}
