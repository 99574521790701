import React from 'react';
import classes from "../OptionsList.module.scss";
import Button from "../../../../UI/Button/Button";

const OptionsHeader = ({setActiveTab}) => {

    const onTabSwitchHandler = (event) => setActiveTab(event.target.id);

    return (
        <div className={classes.OptionsList__top}>
            <h2>Добавление опции</h2>
            <div className={classes.OptionsList__content}>
                <div className={classes.OptionsList__topItem}>
                    <Button
                        id={"one"}
                        type={"button"}
                        onClick={onTabSwitchHandler}
                        label={"Типы"}
                    />
                </div>
                <div className={classes.OptionsList__topItem}>
                    <Button
                        id={"two"}
                        type={"button"}
                        onClick={onTabSwitchHandler}
                        label={"Размеры"}
                    />
                </div>
                <div className={classes.OptionsList__topItem}>
                    <Button
                        id={"three"}
                        type={"button"}
                        onClick={onTabSwitchHandler}
                        label={"Характеристика"}
                    />
                </div>
            </div>
        </div>
    );
};

export default OptionsHeader;
