export const DateFromTimestampHelper = (value) => {
    let dateObj = new Date(value);

    let year = dateObj.getFullYear();
    let month = dateObj.getMonth() + 1;
    month = ('0' + month).slice(-2);
    let date = dateObj.getDate();
    date = ('0' + date).slice(-2);
    let hour = dateObj.getHours();
    hour = ('0' + hour).slice(-2);
    let minute = dateObj.getMinutes();
    minute = ('0' + minute).slice(-2);
    return `${date}/${month}/${year} ${hour}:${minute}`;
}
