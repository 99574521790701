// GENERAL TYPES

export const SHOW_ERROR_ALERT = "SHOW_ERROR_ALERT";
export const SHOW_SUCCESS_ALERT = "SHOW_SUCCESS_ALERT";
export const SET_UPDATE_DATE = "SET_UPDATE_DATE";
export const SET_PRODUCTS_LIST = "SET_PRODUCTS_LIST";
export const GET_TITLES = "GET_TITLES";
export const SET_SEARCH_VALUE = "SET_SEARCH_VALUE";
export const SET_SEARCHED_PRODUCTS = "SET_SEARCHED_PRODUCTS";
export const CHANGE_UNCOVER_STATE = "CHANGE_UNCOVER_STATE";
export const SET_LANGUAGE = "SET_LANGUAGE";
export const CHANGE_DELETE_DIALOG = "CHANGE_DELETE_DIALOG";
export const SET_SIZES = "SET_SIZES";
export const SET_DESCRIPTIONS = "SET_DESCRIPTIONS";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const DELETE_CURRENT_USER = "DELETE_CURRENT_USER";
export const CHANGE_OPTION_DIALOG = "CHANGE_OPTION_DIALOG";

// FILTERS TYPES

export const SET_FILTER_BY_TYPE = "SET_FILTER_BY_TYPE";
export const REMOVE_FILTER_BY_TYPE = "REMOVE_FILTER_BY_TYPE";
export const SET_FILTER_BY_DELIVERY_TIME = "SET_FILTER_BY_DELIVERY_TIME";
export const REMOVE_FILTER_BY_DELIVERY_TIME = "REMOVE_FILTER_BY_DELIVERY_TIME";
export const SET_FILTER_BY_DESCRIPTION = "SET_FILTER_BY_DESCRIPTION";
export const REMOVE_FILTER_BY_DESCRIPTION = "REMOVE_FILTER_BY_DESCRIPTION";
export const SET_FILTER_BY_CODE = "SET_FILTER_BY_CODE";
export const REMOVE_FILTER_BY_CODE = "REMOVE_FILTER_BY_CODE";
export const SET_FILTER_BY_NAME = "SET_FILTER_BY_NAME";
export const REMOVE_FILTER_BY_NAME = "REMOVE_FILTER_BY_NAME";
export const SET_FILTER_BY_INDEX = "SET_FILTER_BY_INDEX";
export const REMOVE_FILTER_BY_INDEX = "REMOVE_FILTER_BY_INDEX";
export const SET_FILTER_BY_COUNT = "SET_FILTER_BY_COUNT";
export const REMOVE_FILTER_BY_COUNT = "REMOVE_FILTER_BY_COUNT";
export const SET_FILTER_BY_SIZE_TWO = "SET_FILTER_BY_SIZE_TWO";
export const SET_FILTER_BY_SIZE_THREE = "SET_FILTER_BY_SIZE_THREE";
export const SET_FILTER_BY_SIZE_ONE = "SET_FILTER_BY_SIZE_ONE";
export const REMOVE_FILTERS = "REMOVE_FILTERS";
