import React, {useEffect, useState} from 'react';
import classes from "./SizesControl.module.scss";
import ControlListItem from "../../../AdminControlList/ControlListItem/ControlListItem";
import Button from "../../../../../UI/Button/Button";
import {useActions} from "../../../../../hooks/useActions/useActions.js";
import {VALIDATION_MESSAGE} from "../../../../../variables/variables";
import ErrorAlert from "../../../../../UI/Alert/ErrorAlert/ErrorAlert";
import {useClientSelector} from "../../../../../store/selectors/useClientSelector";
import {SuccessAlert} from "../../../../../UI/Alert/SuccessAlert/SuccessAlert";

const SizesControl = () => {

    const state = useClientSelector();
    const {showErrorAlertAction, showSuccessAlertAction} = useActions();
    const [type, setType] = useState("text");
    const [checked, setChecked] = useState("one");
    const [size, setSize] = useState("");

    const checkedSizeHandler = (event) => setChecked(event.target.id);
    const handleSizeChange = (event) => setSize(event.target.value);

    useEffect(() => {
        if (checked === "two" || checked === "three") {
            setType("number");
        } else {
            setType("text");
        }
    }, [checked])

    const handleSubmit = () => {
        if (size !== "") {
            // postRequest(`/sizes/${checked}`, size)
            //     .then(() => {
            //         showSuccessAlertAction("Размер успешно добавлен.");
            //         setSize("");
            //     })
            //     .catch(() => showErrorAlertAction("Что-то пошло не так :("))
        } else {
            if (!state.error.show) showErrorAlertAction(VALIDATION_MESSAGE);
        }
    }

    return (
        <>
            <ErrorAlert/>
            <SuccessAlert />
            <div className={classes.SizesControl}>
                <div className={classes.SizesControl__item}>
                    <label className={classes.SizesControl__control}>
                        <input
                            id={"one"}
                            defaultChecked={true}
                            onChange={checkedSizeHandler}
                            name={"size"}
                            value={checked}
                            type={"radio"}
                        />
                        Размер 1
                    </label>
                    <label className={classes.SizesControl__control}>
                        <input
                            id={"two"}
                            onChange={checkedSizeHandler}
                            name={"size"}
                            value={checked}
                            type={"radio"}
                        />
                        Размер 2
                    </label>
                    <label className={classes.SizesControl__control}>
                        <input
                            id={"three"}
                            onChange={checkedSizeHandler}
                            name={"size"}
                            value={checked}
                            type={"radio"}
                        />
                        Размер 3
                    </label>
                </div>
                <ControlListItem
                    type={type}
                    value={size}
                    onChange={handleSizeChange}
                    placeholder={"Значение.."}
                    label={"Размер"}
                />
                <div className={classes.SizesControl__submit}>
                    <Button
                        type={"submit"}
                        onClick={handleSubmit}
                        label={"Сохранить"}
                    />
                </div>
            </div>
        </>
    );
};

export default SizesControl;
