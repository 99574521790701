import React, {FC} from 'react';
import ProductsList from "../../../components/admin/ProductsList/ProductsList";

const ProductsPage = () => {
    return (
        <div className="container">
            <ProductsList />
        </div>
    );
};

export default ProductsPage;
