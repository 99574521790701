import React from 'react';
import TypesControl from "../Controls/TypesControl/TypesControl";
import DescriptionControl from "../Controls/DescriptionControl/DescriptionControl";
import SizesControl from "../Controls/SizesControl/SizesControl";

const OptionsTab = ({activeTab}) => {

    return (
        activeTab === "one"
            ?
            <>
                <TypesControl/>
            </>
            :
            activeTab === "two"
                ?
                <>
                    <SizesControl/>
                </>
                :
                <>
                    <DescriptionControl/>
                </>
    )
};

export default OptionsTab;
