import React, {FC} from 'react';
import Dialog from "../../../../../UI/Dialog/Dialog";
import {useActions} from "../../../../../hooks/useActions/useActions.js";
import {useNavigate, useParams} from "react-router-dom";

const DeleteWarningDialog = () => {

    const {changeDeleteDialogStateAction, showSuccessAlertAction, showErrorAlertAction} = useActions();
    const navigate = useNavigate();
    const {id} = useParams();

    const handleSubmit = () => {
        // deleteRequest(`/products`, id as string)
        //     .then(() => {
        //         showSuccessAlertAction("Товар успешно удален. Перенаправление..");
        //         changeDeleteDialogStateAction();
        //         setTimeout(() => {
        //             navigate("/admin/products");
        //         }, 2000)
        //     })
        //     .catch(() => {
        //         showErrorAlertAction("Что-то пошло не так :(");
        //     })
    }

    const closeHandler = () => {
        return changeDeleteDialogStateAction();
    }

    return (
        <Dialog
            actionName={"Подтвердить"}
            title={"Предупреждение"}
            handleSubmit={handleSubmit}
            closeHandler={closeHandler}
        >
            <p>Вы действительно хотите удалить товар?</p>
        </Dialog>
    );
};

export default DeleteWarningDialog;
