export function sortInAscendingHelper(one, two) {
    // @ts-ignore
    let sizeOne = parseInt(one);
    // @ts-ignore
    let sizeTwo = parseInt(two);

    if (sizeOne > sizeTwo) {
        return 1;
    } else if (sizeTwo > sizeOne) {
        return -1;
    } else {
        return 0;
    }
}
