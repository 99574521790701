import React, {useEffect, useState} from 'react';
import classes from "./ProductsList.module.scss";
import Input from "../../../UI/Input/Input";
import {useActions} from "../../../hooks/useActions/useActions.js";
import {useClientSelector} from "../../../store/selectors/useClientSelector";
import ProductItem from "./ProductItem/ProductItem.jsx";
import {DateFromTimestampHelper} from "../../../helpers/dateFromTimestampHelper/dateFromTimestampHelper.js";

const ProductsList = () => {

    const [searchValue, setSearchValue] = useState("");
    const state = useClientSelector();
    const {getProductsListAction, setSearchedProductsAction} = useActions();

    const handleChange = (event) => {
        setSearchValue(event.target.value);
    }

    const handleSubmit = (event) => {
        if (event.key === "Enter") {
            if (searchValue !== "") {
                setSearchedProductsAction(searchValue, "ru", state.productsWithoutFilters);
            } else {
                getProductsListAction();
            }
        }
    }

    useEffect(() => {
        getProductsListAction();
    }, [])

    return (
        <div className={classes.ProductsList}>
            <div className={classes.ProductsList__top}>
                <h2>Товары</h2>
                <div className={classes.ProductsList__searchWrapper}>
                    <Input
                        onKeyPress={handleSubmit}
                        type={"text"}
                        value={searchValue}
                        onChange={handleChange}
                        placeholder={"Поиск.."}
                    />
                </div>
            </div>
            <div className={classes.ProductsList__content}>
                {
                    state.products?.length
                        ?
                        state.products.map((product) => {

                            let date = DateFromTimestampHelper(product.creationDate);

                            return (
                                <ProductItem
                                    key={product.id}
                                    shortTitle={product.shortTitle}
                                    description={product.description}
                                    price={product.price}
                                    creationDate={date}
                                    id={product.id}
                                />
                            )
                        })
                        :
                        <p>Пусто</p>
                }
            </div>
        </div>
    );
};

export default ProductsList;
