import React, {FC, useState} from 'react';
import {useActions} from "../../../../../hooks/useActions/useActions.js";
import {useClientSelector} from "../../../../../store/selectors/useClientSelector";
import {Formik} from "formik";
import {AdminFormValidationHelper} from "../../../../../helpers/adminFormValidationHelper/adminFormValidationHelper.js";
import {
    DATA_NUMBER,
    PRODUCT_UPDATE_SUCCESS_MESSAGE,
    VALIDATION_MESSAGE
} from "../../../../../variables/variables";
import {SuccessAlert} from "../../../../../UI/Alert/SuccessAlert/SuccessAlert";
import ErrorAlert from "../../../../../UI/Alert/ErrorAlert/ErrorAlert";
import classes from "../../../AdminControlList/AdminControlList.module.scss";
import ControlListItem from "../../../AdminControlList/ControlListItem/ControlListItem";
import Button from "../../../../../UI/Button/Button";
import {useNavigate} from "react-router-dom";
import Select from "../../../../../UI/Select/Select";
import {useAdminSelector} from "../../../../../store/selectors/useAdminSelector";
import LanguageControl from "../../../LanguageControl/LanguageControl";

const UpdateControl = ({product}) => {

    const {showErrorAlertAction, showSuccessAlertAction} = useActions();
    const [language, setLanguage] = useState("ru");
    const [imagesList, setImagesList] = useState(product.images !== undefined ? product.images : []);
    const [isValid, setIsValid] = useState(false);
    const state = useClientSelector();
    const admin = useAdminSelector();
    const navigate = useNavigate();

    const handleLanguage = (event) => {
        switch (event.target.lang) {
            case "en":
                setLanguage("en");
                break
            case "ru":
                setLanguage("ru");
        }
    }

    return (
        <Formik
            validate={(values => {
                AdminFormValidationHelper(values, setIsValid);
            })}
            initialValues={{
                id: product.id,
                code: product.code,
                type: product.type,
                price: product.price,
                shortTitle: product.shortTitle,
                title: product.title,
                sizes: {
                    one: product.sizes?.one,
                    two: product.sizes?.two,
                    three: product.sizes?.three
                },
                images: product.images || [],
                description: product.description,
                amount: product.amount,
                unit: product.unit,
                available: product.available,
                deadlineSupplies: product.deadlineSupplies,
                creationDate: product.creationDate,
                en: {
                    type: product.en.type,
                    title: product.en.title,
                    shortTitle: product.en.shortTitle,
                    description: product.en.description,
                    unit: product.en.unit,
                    deadlineSupplies: product.en.deadlineSupplies
                }
            }}
            onSubmit={async (values, {resetForm}) => {
                if (isValid) {
                    values.id = DATA_NUMBER;
                    values.creationDate = await Date.now();
                    // @ts-ignore
                    values.images = imagesList;
                    // await putRequest(`/products`, product.id, values);
                    //     .then(() => {
                    //         showSuccessAlertAction(PRODUCT_UPDATE_SUCCESS_MESSAGE);
                    //         setTimeout(() => {
                    //             navigate("/admin/products");
                    //         }, 2000)
                    //     })
                    //     .catch(() => {
                    //         showErrorAlertAction("Что-то пошло не так :(");
                    //     })
                    resetForm();
                } else {
                    if (!state.error.show) {
                        return showErrorAlertAction(VALIDATION_MESSAGE);
                    }
                }
            }}
        >
            {({
                  values,
                  handleChange,
                  handleSubmit
              }) => (
                <>
                    <SuccessAlert />
                    <ErrorAlert />
                    <div className={classes.AdminControlList}>
                        <div className={classes.AdminControlList__content}>
                            <div className={classes.AdminControlList__languageControl}>
                                <LanguageControl
                                    language={language}
                                    setLanguage={setLanguage}
                                />
                            </div>
                            {
                                language === "ru"
                                    ?
                                    <>
                                        <ControlListItem
                                            type={"text"}
                                            value={values.code}
                                            onChange={handleChange}
                                            name={"code"}
                                            label={"Код*"}
                                            placeholder={"Код"}
                                        />
                                        <div className={classes.AdminControlList__item}>
                                            <p>Тип*</p>
                                            <Select
                                                value={values.type}
                                                onChange={handleChange}
                                                name={"type"}
                                                options={admin.types.ru !== null && admin.types.ru}
                                            />
                                        </div>
                                        <ControlListItem
                                            type={"text"}
                                            value={values.shortTitle}
                                            onChange={handleChange}
                                            name={"shortTitle"}
                                            label={"Короткое наименование*"}
                                            placeholder={"Короткое наименование"}
                                        />
                                        <ControlListItem
                                            type={"text"}
                                            value={values.title}
                                            onChange={handleChange}
                                            name={"title"}
                                            label={"Полное наименование*"}
                                            placeholder={"Полное наименование"}
                                        />
                                        <div className={classes.AdminControlList__item}>
                                            <p>Размер 1 (мм)*</p>
                                            <Select
                                                value={values.sizes.one}
                                                onChange={handleChange}
                                                name={"sizes.one"}
                                                options={admin.sizes.one !== null && admin.sizes.one}
                                            />
                                        </div>
                                        <div className={classes.AdminControlList__item}>
                                            <p>Размер 2 (мм)*</p>
                                            <Select
                                                value={values.sizes.two}
                                                onChange={handleChange}
                                                name={"sizes.two"}
                                                options={admin.sizes.two !== null && admin.sizes.two}
                                            />
                                        </div>
                                        <div className={classes.AdminControlList__item}>
                                            <p>Размер 3 (мм)*</p>
                                            <Select
                                                value={values.sizes.three}
                                                onChange={handleChange}
                                                name={"sizes.three"}
                                                options={admin.sizes.three !== null && admin.sizes.three}
                                            />
                                        </div>
                                        <div className={classes.AdminControlList__item}>
                                            <p>Характеристика*</p>
                                            <Select
                                                value={values.description}
                                                onChange={handleChange}
                                                name={"description"}
                                                options={admin.descriptions.ru !== null && admin.descriptions.ru}
                                            />
                                        </div>
                                        <ControlListItem
                                            type={"number"}
                                            value={values.amount}
                                            onChange={handleChange}
                                            name={"amount"}
                                            label={"Кол-во*"}
                                            placeholder={"Кол-во"}
                                        />
                                        <ControlListItem
                                            type={"number"}
                                            value={values.price}
                                            onChange={handleChange}
                                            name={"price"}
                                            label={"Цена с НДС за ед.изм.*"}
                                            placeholder={"Цена с НДС за ед.изм."}
                                        />
                                        <ControlListItem
                                            type={"text"}
                                            value={values.unit}
                                            onChange={handleChange}
                                            name={"unit"}
                                            label={"Единица измерения*"}
                                            placeholder={"Единица измерения"}
                                        />
                                        <ControlListItem
                                            type={"number"}
                                            value={values.available}
                                            onChange={handleChange}
                                            name={"available"}
                                            label={"Доступно*"}
                                            placeholder={"Доступно"}
                                        />
                                        <ControlListItem
                                            type={"text"}
                                            value={values.deadlineSupplies}
                                            onChange={handleChange}
                                            name={"deadlineSupplies"}
                                            label={"Срок поставки*"}
                                            placeholder={"Срок поставки"}
                                        />
                                    </>
                                    :
                                    <>
                                        <ControlListItem
                                            type={"text"}
                                            value={values.en.type}
                                            onChange={handleChange}
                                            name={"en.type"}
                                            label={"Тип*"}
                                            placeholder={"Тип"}
                                        />
                                        <ControlListItem
                                            type={"text"}
                                            value={values.en.shortTitle}
                                            onChange={handleChange}
                                            name={"en.shortTitle"}
                                            label={"Короткое наименование*"}
                                            placeholder={"Короткое наименование*"}
                                        />
                                        <ControlListItem
                                            type={"text"}
                                            value={values.en.title}
                                            onChange={handleChange}
                                            name={"en.title"}
                                            label={"Полное наименование*"}
                                            placeholder={"Полное наименование*"}
                                        />
                                        <div className={classes.AdminControlList__item}>
                                            <p>Характеристика*</p>
                                            <Select
                                                value={values.en.description}
                                                onChange={handleChange}
                                                name={"en.description"}
                                                options={admin.descriptions.en !== null && admin.descriptions.en}
                                            />
                                        </div>
                                        <ControlListItem
                                            type={"text"}
                                            value={values.en.unit}
                                            onChange={handleChange}
                                            name={"en.unit"}
                                            label={"Единица измерения*"}
                                            placeholder={"Единица измерения"}
                                        />
                                        <ControlListItem
                                            type={"text"}
                                            value={values.en.deadlineSupplies}
                                            onChange={handleChange}
                                            name={"en.deadlineSupplies"}
                                            label={"Срок поставки*"}
                                            placeholder={"Срок поставки"}
                                        />
                                    </>
                            }
                            <Button
                                type={"submit"}
                                onClick={handleSubmit}
                                label={"Обновить"}
                            />
                        </div>
                    </div>
                </>
            )}
        </Formik>
    );
};

export default UpdateControl;
