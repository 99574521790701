import {applyMiddleware, combineReducers, createStore} from "redux";
import {rootReducer} from "./reducers/rootReducer.js";
import thunk from "redux-thunk";
import {adminReducer} from "./reducers/adminReducer.js";

export const reducers = combineReducers({
    client: rootReducer,
    admin: adminReducer
});

export const setupStore = createStore(reducers, applyMiddleware(thunk));
