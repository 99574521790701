import React, {useEffect} from 'react';
import classes from "./ErrorAlert.module.scss";
import {useClientSelector} from "../../../store/selectors/useClientSelector";
import {useActions} from "../../../hooks/useActions/useActions.js";

const ErrorAlert = () => {

    const state = useClientSelector();
    const {showErrorAlertAction} = useActions();

    useEffect(() => {
        if (state.error.show) {
            setTimeout(() => {
                showErrorAlertAction("");
            }, 5000)
        }
    }, [state.error.show])

    return (
        state.error.show
            ?
            <div className={classes.ErrorAlert}>
                <span>{state.error.message}</span>
            </div>
            :
            null
    );
};

export default ErrorAlert;
