import {
    CHANGE_DELETE_DIALOG,
    CHANGE_UNCOVER_STATE,
    GET_TITLES,
    SET_DESCRIPTIONS,
    SET_LANGUAGE,
    SET_PRODUCTS_LIST,
    SET_SEARCH_VALUE,
    SET_SEARCHED_PRODUCTS,
    SET_SIZES,
    SET_UPDATE_DATE,
    SET_CURRENT_USER,
    SHOW_ERROR_ALERT,
    SHOW_SUCCESS_ALERT,
    DELETE_CURRENT_USER,
    CHANGE_OPTION_DIALOG,
    REMOVE_FILTERS,
    REMOVE_FILTER_BY_TYPE,
    SET_FILTER_BY_DESCRIPTION,
    REMOVE_FILTER_BY_DESCRIPTION,
    SET_FILTER_BY_TYPE,
    SET_FILTER_BY_INDEX,
    REMOVE_FILTER_BY_INDEX,
    SET_FILTER_BY_SIZE_TWO,
    SET_FILTER_BY_SIZE_THREE,
    SET_FILTER_BY_SIZE_ONE,
    SET_FILTER_BY_CODE,
    REMOVE_FILTER_BY_CODE,
    SET_FILTER_BY_NAME,
    REMOVE_FILTER_BY_NAME,
    SET_FILTER_BY_DELIVERY_TIME, REMOVE_FILTER_BY_DELIVERY_TIME, REMOVE_FILTER_BY_COUNT, SET_FILTER_BY_COUNT
} from "./actionTypes.js";
import {sortInAscendingHelper} from "../../helpers/sortInAscendingHelper/sortInAscendingHelper.js";
import {sortInDescendingHelper} from "../../helpers/sortInDescendingHelper/sortInDescendingHelper.js";

export const showErrorAlertAction = (value) => {
    return (dispatch) => {
        dispatch({type: SHOW_ERROR_ALERT, payload: value});
    }
}

export const showSuccessAlertAction = (value) => {
    return (dispatch) => {
        dispatch({type: SHOW_SUCCESS_ALERT, payload: value});
    }
}

export const getProductsListAction = (products) => {
    return (dispatch) => {
        dispatch({type: SET_PRODUCTS_LIST, payload: products});
    }
}

export const setSearchValueAction = (value) => {
    return (dispatch) => {
        dispatch({type: SET_SEARCH_VALUE, payload: value});
    }
}

export const setSearchedProductsAction = (query, language, products) => {
    return (dispatch) => {
        let data = products.filter(product => {
            return product["4"].toLowerCase().trim().includes(query.toLowerCase().trim())
                || product["1"].toLowerCase().trim().includes(query.toLowerCase().trim());
        });
        dispatch({type: SET_SEARCHED_PRODUCTS, payload: data})
    }
}

export const changeUncoverStateAction = () => {
    return (dispatch) => {
        dispatch({type: CHANGE_UNCOVER_STATE});
    }
}

export const setLanguageAction = (value) => {
    return (dispatch) => {
        dispatch({type: SET_LANGUAGE, payload: value});
    }
}

export const changeDeleteDialogStateAction = () => {
    return (dispatch) => {
        dispatch({type: CHANGE_DELETE_DIALOG});
    }
}

export const setDescriptionsAction = () => {
    let descriptions = {
        en: [],
        ru: []
    };

    return async (dispatch) => {
        // await getRequest(`/descriptions`)
        //     .then(response => {
        //         let data = response.val();
        //
        //         for (let i in data) {
        //             if (typeof descriptions.en !== "string") {
        //                 descriptions.en.push(data[i].en);
        //             }
        //
        //             if (typeof descriptions.ru !== "string") {
        //                 descriptions.ru.push(data[i].ru);
        //             }
        //         }
        //     })
        dispatch({type: SET_DESCRIPTIONS, payload: descriptions});
    }
}

export const setSizesAction = () => {

    let data = {
        one: [],
        two: [],
        three: []
    };

    return async (dispatch) => {
        // getRequest(`/sizes`)
        //     .then((response) => {
        //         let result = response.val();
        //
        //         for (let i in result.one) {
        //             let element = result.one[i];
        //             data.one.push(element);
        //         }
        //
        //         for (let i in result.two) {
        //             let element = result.two[i];
        //             data.two.push(element);
        //         }
        //
        //         for (let i in result.three) {
        //             let element = result.three[i];
        //             data.three.push(element);
        //         }
        //
        //         dispatch({type: SET_SIZES, payload: data})
        //     })
    }
}

export const setCurrentUserAction = (user) => {
    return (dispatch) => {
        localStorage.setItem("user", JSON.stringify(user));
        dispatch({type: SET_CURRENT_USER, payload: user})
    }
}

export const deleteCurrentUserAction = () => {
    return (dispatch) => {
        localStorage.removeItem("user");
        dispatch({type: DELETE_CURRENT_USER});
    }
}

export const changeFilterDialogAction = (value, state) => {
    return (dispatch) => {

        let data = {
            key: value,
            state
        };

        dispatch({type: CHANGE_OPTION_DIALOG, payload: data});
    }
}


export const setFilterByIndexAction = (control, products) => {
    return (dispatch) => {

        let data = {
            products,
            control
        };

        let from = parseInt(control.from);
        let to = parseInt(control.to);

        data.products = products.filter((product) => {

            let productIndex = product["0"];

            if (control.from === "") {
                from = 0;
            }

            if (control.to === "") {
                to = products.length;
            }

            // @ts-ignore
            return productIndex >= from && productIndex <= to;
        });

        dispatch({type: SET_FILTER_BY_INDEX, payload: data});
    }
}

export const removeFilterByIndexAction = () => {
    return (dispatch) => {
        dispatch({type: REMOVE_FILTER_BY_INDEX});
    }
}

export const setFilterByCountAction = (control, products) => {
    return (dispatch) => {

        let data = {
            products,
            control
        };

        let from = parseInt(control.from);
        let to = parseInt(control.to);

        data.products = products.filter((product) => {

            let productCount = product["9"];

            if (control.from === "") {
                from = 0;
            }

            if (control.to === "") {
                to = products.length;
            }

            // @ts-ignore
            return productCount >= from && productCount <= to;
        });

        dispatch({type: SET_FILTER_BY_COUNT, payload: data});
    }
}

export const removeFilterByCountAction = () => {
    return (dispatch) => {
        dispatch({type: REMOVE_FILTER_BY_COUNT});
    }
}

export const setFilterByTypeAction = (types, language, products) => {
    return (dispatch) => {

        let data = {
            products,
            types
        };

        data.products = products.filter(product => types.includes(product["1"]));

        dispatch({type: SET_FILTER_BY_TYPE, payload: data});
    }
}

export const removeFilterByTypeAction = () => {
    return (dispatch) => {
        dispatch({type: REMOVE_FILTER_BY_TYPE});
    }
}

export const setFilterByDeliveryTimeAction = (deliveryTimes, language, products) => {
    return (dispatch) => {

        let data = {
            products,
            deliveryTimes
        };

        data.products = products.filter(product => deliveryTimes.includes(product["10"]));

        dispatch({type: SET_FILTER_BY_DELIVERY_TIME, payload: data});
    }
}

export const removeFilterByDeliveryTimeAction = () => {
    return (dispatch) => {
        dispatch({type: REMOVE_FILTER_BY_DELIVERY_TIME});
    }
}

export const setFilterByDescriptionAction = (query, products) => {
    return (dispatch) => {

        let data = {
            products
        };

        data.products = data.products.filter(product => {
            let description = product["8"].toString().toLowerCase().trim();
            let trimmedQuery = query.toLowerCase().trim();
            return description.includes(trimmedQuery);
        });

        dispatch({type: SET_FILTER_BY_DESCRIPTION, payload: data});
    }
}

export const setFilterByNameAction = (query, products) => {
    return (dispatch) => {

        let data = {
            products
        };

        data.products = data.products.filter(product => {
            let description = product["4"].toString().toLowerCase().trim();
            let trimmedQuery = query.toLowerCase().trim();
            return description.includes(trimmedQuery);
        });

        dispatch({type: SET_FILTER_BY_NAME, payload: data});
    }
}

export const removeFilterByNameAction = () => {
    return (dispatch) => {
        dispatch({type: REMOVE_FILTER_BY_NAME});
    }
}

export const removeFilterByDescriptionAction = () => {
    return (dispatch) => {
        dispatch({type: REMOVE_FILTER_BY_DESCRIPTION});
    }
}

export const setFilterByCodeAction = (query, products) => {
    return (dispatch) => {

        let data = {
            products
        };

        data.products = data.products.filter(product => {
            let code = product["2"].toString().toLowerCase().trim();
            let trimmedQuery = query.toLowerCase().trim();
            return code.includes(trimmedQuery);
        });

        dispatch({type: SET_FILTER_BY_CODE, payload: data});
    }
}

export const removeFilterByCodeAction = () => {
    return (dispatch) => {
        dispatch({type: REMOVE_FILTER_BY_CODE});
    }
}

export const removeFiltersAction = () => {
    return (dispatch) => {
        dispatch({type: REMOVE_FILTERS});
    }
}

export const sortInOneAscendingAction = (products) => {
    return (dispatch) => {

        let data = products.sort((one, two) => {
            return sortInAscendingHelper(one["5"], two["5"]);
        });

        dispatch({type: SET_FILTER_BY_SIZE_ONE, payload: data});
    }
}

export const sortInOneDescendingAction = (products) => {
    return (dispatch) => {

        let data = products.sort((one, two) => {
            return sortInDescendingHelper(one["5"], two["5"]);
        });

        dispatch({type: SET_FILTER_BY_SIZE_ONE, payload: data});
    }
}

export const sortInTwoAscendingAction = (products) => {
    return (dispatch) => {

        let data = products.sort((one, two) => {
            return sortInAscendingHelper(one["6"], two["6"]);
        });

        dispatch({type: SET_FILTER_BY_SIZE_TWO, payload: data});
    }
}

export const sortInTwoDescendingAction = (products) => {
    return (dispatch) => {

        let data = products.sort((one, two) => {
            return sortInDescendingHelper(one["6"], two["6"]);
        });

        dispatch({type: SET_FILTER_BY_SIZE_TWO, payload: data});
    }
}

export const sortInThreeAscendingAction = (products) => {
    return (dispatch) => {

        let data = products.sort((one, two) => {
            return sortInAscendingHelper(one["7"], two["7"]);
        });

        dispatch({type: SET_FILTER_BY_SIZE_THREE, payload: data});
    }
}

export const sortInThreeDescendingAction = (products) => {
    return (dispatch) => {

        let data = products.sort((one, two) => {
            return sortInDescendingHelper(one["7"], two["7"]);
        });

        dispatch({type: SET_FILTER_BY_SIZE_THREE, payload: data});
    }
}
