import React from 'react';
import {Navigate} from "react-router-dom";

const SecurityRoute = ({children}) => {

    if (localStorage.getItem("user") == null || localStorage.getItem("user") === undefined) {
        return <Navigate to={`/auth`} />
    }

    return children;
};

export default SecurityRoute;
