import React from 'react';
import classes from "./ProductItem.module.scss";
import Row from "./Row/Row.jsx";
import {useNavigate} from "react-router-dom";

const ProductItem = (
    {
        id,
        shortTitle,
        price,
        creationDate,
        description
    }
) => {

    const navigate = useNavigate();

    const handleRedirect = () => {
        navigate(`/admin/products/${id}`);
    }

    return (
        <div onClick={handleRedirect} className={classes.ProductItem}>
            <div className={classes.ProductItem__content}>
                <Row image={"/images/home-icon.svg"} text={shortTitle} />
                <Row image={"/images/description-icon.svg"} text={description} />
                <Row image={"/images/price-icon.svg"} text={price} />
            </div>
            <div className={classes.ProductItem__footer}>
                {creationDate}
            </div>
        </div>
    );

};

export default ProductItem;
